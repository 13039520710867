import * as React from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Waranty = () => {

  const data = useStaticQuery(
    graphql`
      query {
        contentfulWarranty(contentfulid: {eq: 1}) {
          content {
            raw
          }
        }
      }
    `
  )

  const Text = ({ children }) => <p>{children}</p>

  const options = {
      renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
  }

  return (

    <Layout page="internal">
      <Seo title="Warranty" />
      <Container className="container">
        <div className="customWrapper">
          <div className="defaultWrapper">
            <h1>Warranty</h1>
            {renderRichText(data.contentfulWarranty.content, options)}
          </div>
        </div>
      </Container>
    </Layout>
  )

}

export default Waranty